import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import ParallaxImage from '../parallax/ParallaxImage';
import SectionTrigger from '../SectionTrigger';
import VideoPlayer from 'src/components/VideoPlayer';

function McEnroeContent({ images, videos }) {
    const [
        mcEnroeImg00,
        mcEnroeImg01,
        mcEnroeImg02,
        mcEnroeImg03,
        mcEnroeImg04,
        mcEnroeImg05,
        mcEnroeImg06,
        mcEnroeImg07,
        mcEnroeImg08,
        mcEnroeImg09,
        mcEnroeImg10,
        mcEnroeImg11,
        mcEnroeImg12,
        mcEnroeImg13,
    ] = images;
    const [mcEnroeVid01] = videos;
    // const scrollOffset = [40, -20];
    const scrollOffset = [0, 0];

    return (
        <div>
            <SectionTrigger bgColor="#07120F" textColor="#F7F0F0">
                <div className="mc-hero">
                    <div className="mc-hero__container">
                        <div className="mc-hero__header">
                            <img className="mc-hero__header-logo" src={mcEnroeImg00} alt="" />
                            <h3 className="main-heading mc-hero__header-hdg">
                                McENROE
                                <br />
                                Vs McENROE
                            </h3>
                            <p className="main-copy mc-hero__header-copy">
                                MICHELOB ULTRA
                            </p>
                        </div>
                    </div>
                    <img className="mc-hero__img" src={mcEnroeImg01} alt="" />
                </div>
            </SectionTrigger>
            <SectionTrigger
                bgColor="#A32729"
                className="mc-section mc-section--1"
                textColor="#F7F0F0"
            />
            <SectionTrigger
                bgColor="#000000"
                className="mc-section mc-section--2"
                textColor="#F7F0F0"
            />
            <SectionTrigger
                bgColor="#A32729"
                className="mc-section mc-section--3"
                textColor="#F7F0F0"
            />
            <SectionTrigger
                bgColor="#000000"
                className="mc-section mc-section--4"
                textColor="#F7F0F0"
            />
            <div className="mc__container">
                <h3 className="main-heading mc__heading mc__heading--1">
                    SYNOPSIS
                </h3>

                <p className="main-copy mc__copy mc__copy--1">
                    Michelob ULTRA, the beer brand that always reminds us to prioritize joy, invited legendary tennis player John McEnroe to look back at his incredible career and face-off against himself in the world’s first real vs. virtual tennis match. “
                </p>

                <p className="mc__copy mc__copy--5">
                    WATCH THE TRAILER
                </p>
                <VideoPlayer
                    className="mc__vid mc__vid--1"
                    src={mcEnroeVid01}
                    poster={mcEnroeImg04}
                />

                <h3 className="main-heading approach mc__heading mc__heading--2">
                    APPROACH
                </h3>

                <p className="main-copy mc__copy mc__copy--2">
                    Michelob ULTRA believes “It’s Only Worth It If You Enjoy It.” And its messaging became especially relevant at a time when more and more athletes have begun to question whether a win-at-all-costs mentality brings them joy.
                </p>

                <p className="main-copy mc__copy mc__copy--3">
                    With “McEnroe vs. McEnroe,” Michelob ULTRA helped shine a light on the subject by bringing John McEnroe, an athlete known for being tough on the court, to reflect on his career and face his toughest opponent: himself.
                    <span>
                        The real John McEnroe played virtual versions of himself from the 1970s and ’80s, powered by AR MetaHuman technology, and on a specially built tennis court — the first-ever half real, half virtual one. On the virtual half of the court, we projected McEnroe’s avatar onto a particle-mist screen, and on the physical half, we custom-built the surface with acrylic fiber to resemble his favorite courts from the 1980s.
                    </span>
                </p>


                <blockquote className="blockquote mc__blockquote mc__blockquote--1">
                    “A world-first physical vs virtual tennis match between the real-life John McEnroe and his digital avatars from the past…”
                </blockquote>
                <h3 className="main-heading approach1 mc__heading mc__heading--3">
                    APPROACH
                </h3>

                <blockquote className="blockquote mc__blockquote mc__blockquote--2">
                    “…combining AI, Unreal Engine, body tracking and A SOULFUL STORY. Awarded with 5 Cannes Lions.. ”
                </blockquote>

                <h3 className="main-heading impact mc__heading mc__heading--4">
                    IMPACT IMPACT
                </h3>

                <p className="main-copy mc__copy mc__copy--4">
                    With over 10 million views across ESPN’s channels, McEnroe vs McEnroe attracted more eyeballs than the 2021 NBA finals – making this one of the most-watched sports events of the year.
                    <span>
                        Awarded with 5x Cannes Lions and 1x FWA.
                    </span>
                </p>

                <img
                    className="mc__img mc__img--2"
                    src={mcEnroeImg02}
                    alt=""
                />
                <img
                    className="mc__img mc__img--3"
                    src={mcEnroeImg03}
                    alt=""
                />
                <Parallax className="mc__img mc__img--4" y={scrollOffset}>
                    <ParallaxImage src={mcEnroeImg05} alt="" />
                </Parallax>
                <img
                    className="mc__img mc__img--5"
                    src={mcEnroeImg06}
                    alt=""
                />
                <Parallax className="mc__img mc__img--6" y={scrollOffset}>
                    <ParallaxImage src={mcEnroeImg07} alt="" />
                </Parallax>
                <img
                    className="mc__img mc__img--7"
                    src={mcEnroeImg07}
                    alt=""
                />
                <img
                    className="mc__img mc__img--8"
                    src={mcEnroeImg09}
                    alt=""
                />
                <img
                    className="mc__img mc__img--9"
                    src={mcEnroeImg08}
                    alt=""
                />
                <img
                    className="mc__img mc__img--10"
                    src={mcEnroeImg10}
                    alt=""
                />
                <img
                    className="mc__img mc__img--11"
                    src={mcEnroeImg11}
                    alt=""
                />
                <img
                    className="global-box-shadow-2 mc__img mc__img--12"
                    src={mcEnroeImg12}
                    alt=""
                />
                <img
                    className="global-box-shadow-2 mc__img mc__img--13"
                    src={mcEnroeImg13}
                    alt=""
                />
            </div>
            <SectionTrigger
                bgColor="#F3766A"
                className="mc-section"
                textColor="#fff"
            />
        </div>
    )
}

export default McEnroeContent;