import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/mcenroe.scss';
// hooks
import mcenroeMobileMedia from '../../hooks/mcenroeMobileMedia';
import mcenroeMedia from '../../hooks/mcenroeMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// lib
import handlePageTransition from 'src/lib/handle-page-transition';
// components
import SEO from 'src/components/SEO';
import McEnroeContent from '../../components/mcenroe/McEnroeContent';
import McEnroeContentMobile from '../../components/mcenroe/McEnroeContentMobile';



function McEnroePage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = mcenroeMobileMedia();
  const media = mcenroeMedia();

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  const vid01 =
  'https://player.vimeo.com/progressive_redirect/playback/736957368/rendition/1080p/file.mp4?loc=external&signature=cb23e4e41507262813db5374115e9361f9af34a17aa36f59e7f06dd02993aff9';

  return (
    <div>
      <SEO title="McEnroe | Falkon Content" />
      <div className="mc">
        {isDesktop
          ? (<McEnroeContent images={images} videos={[vid01]} />)
          : (<McEnroeContentMobile images={mobileImages} videos={[vid01]} />)
        }
      </div>
    </div>
  );
}

export default React.memo(McEnroePage);
